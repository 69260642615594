body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #d2a97a;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #d2a97a;
}

nav a {
  color: #333;
  text-decoration: none;
  margin: 0 10px;
  font-size: 1.2rem;
}

nav a:hover {
  color: #000;
}

.header {
  background-color: #d2a97a;
  padding: 100px 20px;
  text-align: center;
}

.header h1 {
  color: #000;
  font-size: 4rem;
}

.header p {
  color: #333;
  font-size: 1.5rem;
}

.section {
  padding: 50px 20px;
  text-align: center;
  background-color: #d2a97a;
}

.section h2 {
  color: #000;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.section p {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.section img {
  max-width: 100%;
  height: auto;
}

.section-button {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px;
}

.section-button:hover {
  background-color: #333;
}
